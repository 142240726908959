import React, { useCallback, useEffect, useRef, useState } from 'react'
// @ts-ignore
import TimeMe from 'timeme.js'

import { useMutation, gql } from '@apollo/client'
import { GlobalTrackingProvider, useFundConfig } from '@flock/shared-ui'
import * as Sentry from '@sentry/gatsby'
import {
  TrackingWrapperCreateLandingFrontendEventDocument,
  Core_CreateLandingFrontendEventRequestInput,
  TrackingWrapperUpdateLandingFrontendEventDocument,
  Core_UpdateLandingFrontendEventRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { UserEventType } from '@flock/utils'
import { trackPage, track } from './analytics'
import { GATSBY_ENV } from '../constants'

export const CREATE_LANDING_FRONTEND_EVENT = gql`
  mutation TrackingWrapperCreateLandingFrontendEvent(
    $input: Core_CreateLandingFrontendEventRequestInput!
  ) {
    createLandingFrontendEvent(input: $input) {
      _empty
    }
  }
`

export const UPDATE_LANDING_FRONTEND_EVENT = gql`
  mutation TrackingWrapperUpdateLandingFrontendEvent(
    $input: Core_UpdateLandingFrontendEventRequestInput!
  ) {
    updateLandingFrontendEvent(input: $input) {
      _empty
    }
  }
`

// https://codesandbox.io/s/useinterval-demo-typescript-3zddk?file=/src/index.tsx:755-812
interface IUseInterval {
  (callback: () => void, interval: number): void
}

const useInterval: IUseInterval = (callback, interval) => {
  const savedCallback = useRef<(() => void) | null>(null)
  // After every render, save the latest callback into our ref.
  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    const id = setInterval(tick, interval)
    return () => clearInterval(id)
  }, [interval])
}

type TrackingWrapperProps = {
  children: React.ReactNode
  groupedEvents?: { [key: string]: string }
}

const TrackingWrapper = (props: TrackingWrapperProps) => {
  const { children, groupedEvents } = props

  const { shortName } = useFundConfig()

  const [sendEvent] = useMutation(
    TrackingWrapperCreateLandingFrontendEventDocument
  )
  const [updateEvent] = useMutation(
    TrackingWrapperUpdateLandingFrontendEventDocument
  )

  const updateEventFunction = useCallback(
    async (input: Core_UpdateLandingFrontendEventRequestInput) => {
      try {
        await updateEvent({ variables: { input } })
      } catch (e) {
        // network errors can happen a lot due to how often we are sending events
        // ignore if it is a network error.
        if (
          e.message.includes('Failed to fetch') ||
          e.message.includes('Load failed')
        ) {
          return
        }
        Sentry.captureException(e, {
          extra: {
            actionType: input.actionType,
            additionalInfo: input.additionalInfo,
          },
        })
      }
    },
    [updateEvent]
  )

  // Heartbeat fn to capture page_visit duration as it occurs
  const [heartbeat, setHeartbeat] = useState(0)
  const [activeTime, setActiveTime] = useState(0)
  const [currentPage, setCurrentPage] = useState('')
  useInterval(() => {
    setHeartbeat(heartbeat + 15)
    if (typeof window !== `undefined`) {
      if (currentPage !== window.location.pathname) {
        setCurrentPage(window.location.pathname)
        setHeartbeat(0)
      }
    }

    // Stop saving after 10 minutes on a page or if user is inactive
    if (
      heartbeat !== 0 &&
      activeTime !== TimeMe.getTimeOnCurrentPageInSeconds() &&
      heartbeat < 600 &&
      GATSBY_ENV === 'production'
    ) {
      setActiveTime(TimeMe.getTimeOnCurrentPageInSeconds())
      track(
        'pageview-duration',
        {
          timeOnPage: TimeMe.getTimeOnCurrentPageInSeconds(),
          totalTimeOnPage: heartbeat,
          pageViewed: currentPage,
        },
        UserEventType.PAGE_DURATION,
        undefined,
        updateEventFunction,
        '',
        ''
      )
    }
  }, 15000)

  useEffect(() => {
    TimeMe.initialize({
      currentPageName: window?.location?.pathname || 'unknown', // current page
      idleTimeoutInSeconds: 5, // seconds
    })

    TimeMe.startTimer()
  }, [])

  const sendEventFunction = useCallback(
    async (input: Core_CreateLandingFrontendEventRequestInput) => {
      try {
        await sendEvent({ variables: { input } })
      } catch (e) {
        // network errors can happen a lot due to how often we are sending events
        // ignore if it is a network error.
        if (
          e.message.includes('Failed to fetch') ||
          e.message.includes('Load failed')
        ) {
          return
        }
        Sentry.captureException(e, {
          extra: {
            actionType: input.actionType,
            slug: input.slug,
            urlPath: input.urlPath,
          },
        })
      }
    },
    [sendEvent]
  )

  const trackingFunctionWrapper = useCallback(
    (name: string, properties?: any, eventType?: string, slug?: string) => {
      track(name, properties, eventType, sendEventFunction, undefined, slug)
    },
    [sendEventFunction]
  )

  const trackPageFunctionWrapper = useCallback(
    (name: string, properties?: any, title?: string) => {
      trackPage(name, properties, sendEventFunction, updateEventFunction, title)
    },
    [sendEventFunction, updateEventFunction]
  )

  return (
    <GlobalTrackingProvider
      siteName={`${shortName}-landing-fe`}
      trackFn={trackingFunctionWrapper}
      trackPageFn={trackPageFunctionWrapper}
      groupedEvents={groupedEvents || {}}
    >
      {children}
    </GlobalTrackingProvider>
  )
}

TrackingWrapper.defaultProps = {
  groupedEvents: {},
}

export default TrackingWrapper
