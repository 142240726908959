// constants related to tracking user events
/* eslint-disable import/prefer-default-export */
export enum UserEventType {
  BUTTON_CLICK = 'button_click',
  PAGE_VISIT = 'page_visit',
  LINK_CLICK = 'link_click',
  ICON_BUTTON_CLICK = 'icon_button_click',
  ACCORDION_CLICK = 'accordion_click',
  PAGE_DURATION = 'page_duration',
  TAB_CLICK = 'tab_click',
}
