import { ThemeProvider } from '@mui/material'
import React from 'react'
import { adminScholasticTheme, scholasticTheme } from '../../theme'

type ScholasticThemeProviderProps = {
  children: React.ReactNode
  isAdmin?: boolean
}

const ScholasticThemeProvider = (props: ScholasticThemeProviderProps) => {
  const { children, isAdmin } = props
  return (
    <ThemeProvider theme={isAdmin ? adminScholasticTheme : scholasticTheme}>
      {children}
    </ThemeProvider>
  )
}

export default ScholasticThemeProvider

ScholasticThemeProvider.defaultProps = {
  isAdmin: false,
}
