import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/gatsby'

type ConfiguredApolloProviderProps = {
  children: React.ReactNode
}

const CriticalOperationNames = ['GetLead', 'CreateLead', 'UpdateLead']

const RetryOperationNames = [
  'GetLead',
  'GetValuation',
  'GetLeadClosingSchedule',
  'PropertyAssets',
]

const ConfiguredApolloProvider = ({
  children,
}: ConfiguredApolloProviderProps) => {
  const apolloLinks = []

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (_, operation) =>
        RetryOperationNames.includes(operation.operationName),
    },
  })

  apolloLinks.push(retryLink)

  const errorLink = onError((errResult) => {
    const { operation, graphQLErrors, networkError } = errResult
    const context = operation.getContext()
    const traceId = context.response?.headers?.get('X-Amzn-Trace-Id')
    const critical = CriticalOperationNames.includes(
      operation.operationName
    ).toString()

    Sentry.captureException(
      new Error(`GQL Operation failed: ${operation.operationName}`),
      {
        tags: {
          traceId,
          critical,
        },
        extra: {
          variables: JSON.stringify(operation.variables, null, 2),
          graphQLErrors: JSON.stringify(graphQLErrors, null, 2),
          networkError: JSON.stringify(networkError, null, 2),
        },
      }
    )
  })
  apolloLinks.push(errorLink)

  let tenant = ''
  if (typeof window !== 'undefined') {
    // Get query parameter "tenant" from URL
    const urlParams = new URLSearchParams(window.location.search)
    tenant = urlParams.get('tenant') as string
  }

  const tenantLink = setContext(async (_, previousContext) => ({
    headers: {
      ...previousContext.headers,
      tenant,
    },
  }))
  apolloLinks.push(tenantLink)

  const uploadLink = createUploadLink({ uri: process.env.GATSBY_APOLLO_URL })
  apolloLinks.push(uploadLink)

  const client = new ApolloClient({
    link: from(apolloLinks as ApolloLink[]),
    cache: new InMemoryCache(),
  })

  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  )
}

export default ConfiguredApolloProvider
