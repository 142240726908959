// These set up types for segment analytics
/// <reference types="@types/segment-analytics" />

import { localStore, UserEventType } from '@flock/utils'
import {
  Core_CreateLandingFrontendEventRequestInput,
  Core_UpdateLandingFrontendEventRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { v4 as uuidv4 } from 'uuid'
// @ts-ignore
import TimeMe from 'timeme.js'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

const EVENT_TOKEN_KEY = 'FLOCK-EVENT-TOKEN'

// If logSegment is true, events are not sent to segment and are
// instead logged to the console.
const logSegment = process.env.GATSBY_LOG_SEGMENT === 'true'

const debugSegment = () =>
  logSegment || window.location.href.includes('localhost')

export const shouldTrack = () => {
  let doNotTrack = false
  if (typeof window !== 'undefined') {
    doNotTrack =
      window.doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      localStore?.getItem('disableTracking') === 'true' ||
      debugSegment()
  }
  return !doNotTrack
}

const getPageTitle = () => {
  if (typeof window !== 'undefined') {
    return window.document.title
  }
  return ''
}

export const getEventTokenUUID = (): string => {
  let token = localStore?.getItem(EVENT_TOKEN_KEY)
  if (!token) {
    token = uuidv4()
    localStore?.setItem(EVENT_TOKEN_KEY, token!)
  }
  return token!
}

export const getQueryParamsAsJson = () => {
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const queryParams: { [k: string]: string } = {}
    params.forEach((value: string, key: string) => {
      queryParams[key] = value
    })
    return queryParams
  }
  return {}
}

const getEventUrlPath = () => {
  if (typeof window !== 'undefined') {
    // need hash since we use it in onboarding to represent different steps.
    return window.location.pathname + window.location.hash
  }
  return ''
}

export const flockTrack = (
  eventType: string,
  slug: string | undefined | null,
  sendEvent?: (input: Core_CreateLandingFrontendEventRequestInput) => void,
  updateEvent?: (input: Core_UpdateLandingFrontendEventRequestInput) => void,
  additionalInfo?: Object,
  title?: string
) => {
  const additionalInfoToSend = { ...additionalInfo, ...getQueryParamsAsJson() }

  if (sendEvent) {
    const input: Core_CreateLandingFrontendEventRequestInput = {
      actionType: eventType,
      slug,
      urlPath: getEventUrlPath(),
      pageTitle: title || getPageTitle(),
      tokenUuid: getEventTokenUUID(),
      eventTime: new Date().getTime(),
      additionalInfo: JSON.stringify(additionalInfoToSend),
      referer: document.referrer,
      userAgent: window.navigator.userAgent,
    }
    sendEvent(input)
  }

  if (updateEvent) {
    const updateInput: Core_UpdateLandingFrontendEventRequestInput = {
      actionType: UserEventType.PAGE_DURATION,
      tokenUuid: getEventTokenUUID(),
      additionalInfo: JSON.stringify(additionalInfoToSend), // duration here
    }
    updateEvent(updateInput)
  }
}

export const track = (
  name: string,
  properties?: any,
  actionType?: string,
  sendEvent?: (input: Core_CreateLandingFrontendEventRequestInput) => void,
  updateEvent?: (input: Core_UpdateLandingFrontendEventRequestInput) => void,
  slug?: string,
  title?: string
) => {
  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)

  if (!shouldTrack()) {
    return
  }
  // maintain backwards compatibility, only call if these new params are provided.
  if (actionType && (sendEvent || updateEvent)) {
    // the slug passed in is the identifier for what section of the page (using tracking context) so
    // still need to join with name of action to identify specific action on page.
    let eventSlug = name
    if (slug && name) {
      eventSlug = `${slug}/${name}`
    }
    flockTrack(
      actionType,
      eventSlug,
      sendEvent,
      updateEvent,
      {
        ...savedParams,
        ...properties,
      },
      title
    )
  }
  if (debugSegment()) {
    console.log('Segment Track', name, {
      ...savedParams,
      ...properties,
    })
  } else {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({ event: name })
    }

    window.analytics.track(name, {
      ...savedParams,
      ...properties,
    })
  }
}

export const trackPage = (
  name: string,
  properties: any,
  sendEvent?: (
    input: Core_CreateLandingFrontendEventRequestInput
  ) => Promise<any>,
  updateEvent?: (
    input: Core_UpdateLandingFrontendEventRequestInput
  ) => Promise<any>,
  title?: string
) => {
  let queryParams: { [k: string]: string } = {}
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)
    params.forEach((value: string, key: string) => {
      queryParams[key] = value
    })

    if (TimeMe.currentPageName !== 'default-page-name') {
      track(
        name,
        {
          timeOnPage: TimeMe.getTimeOnCurrentPageInSeconds(),
          pageViewed: TimeMe.currentPageName,
        },
        UserEventType.PAGE_DURATION,
        undefined,
        updateEvent,
        title
      )
    }

    TimeMe.stopTimer()
    TimeMe.resetAllRecordedPageTimes()

    TimeMe.setCurrentPageName(window?.location?.pathname)
    TimeMe.startTimer()
  }

  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)
  queryParams = {
    ...savedParams,
    ...queryParams,
  }
  localStore?.setItem('queryParams', JSON.stringify(queryParams))

  if (!shouldTrack()) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Page', name, properties)
  } else {
    window.analytics.page({
      title: name,
      url: `${window.location.origin}/${name}`,
      path: `/${name}`,
      search: `${window.location.search}`,
    })
  }

  track(
    name,
    {
      ...properties,
      ...queryParams,
    },
    UserEventType.PAGE_VISIT,
    sendEvent,
    undefined,
    title
  )
}

export type IdentifyProperties = {
  userId?: string
  email?: string
  name?: string
  phone?: string
  brokerUuid?: string
  utmSource?: string
  utmCampaign?: string
  utmMedium?: string
}

export const identify = (properties: IdentifyProperties) => {
  if (!shouldTrack()) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Identify', properties)
  } else if (properties.userId) {
    const { userId, ...otherProperties } = properties
    window.analytics.identify(userId, {
      ...otherProperties,
    })
  } else {
    window.analytics.identify({
      ...properties,
    })
  }
}

export const getGAClientID = () => {
  // cookie is stored in this format '_ga=GA1.2.358520647.1656458798; _gcl=cookie2'
  if (typeof window !== 'undefined' && window.document.cookie !== '') {
    const match = window.document.cookie.match(/_ga=(.+?)(;|$)/)
    if (match !== null && match.length > 1) {
      return match[1].split('.').slice(-2).join('.')
    }
  }
  return ''
}
