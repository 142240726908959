import { CssBaseline } from '@mui/material'
import React from 'react'
import { FundConfigProvider } from '@flock/shared-ui'
import ConfiguredApolloProvider from './apollo'
import TrackingWrapper from './TrackingWrapper'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }: any) => (
  <FundConfigProvider>
    <CssBaseline />
    <ConfiguredApolloProvider>
      <TrackingWrapper>{element}</TrackingWrapper>
    </ConfiguredApolloProvider>
  </FundConfigProvider>
)
