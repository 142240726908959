/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext } from 'react'

export type TrackingProperties = {
  [key: string]: any
}

export type TrackingConfig = {
  name?: string
  properties?: {
    [key: string]: any
  }
}

export type TrackingNode = {
  name: string
  [key: string]: any
  parent?: TrackingNode
}

type TrackingContextProps = {
  trackingNode: TrackingNode | null
  updateTrackingProperties: (properties: TrackingProperties) => void

  // Track and track page must be provided at the outermost tracking context.
  track: (
    eventName: string,
    additionalProperties?: TrackingProperties,
    actionType?: string
  ) => void
  trackPage: (
    pageName: string,
    additionalProperties?: TrackingProperties,
    title?: string
  ) => void
}

export const TrackingContext = createContext({
  track: (_: string, __?: TrackingProperties, ___?: string) => {},
  trackPage: (_: string, __?: TrackingProperties, ___?: string) => {},
  updateTrackingProperties: (_: TrackingProperties) => {},
  trackingNode: null,
} as TrackingContextProps)
const useTracking = () => useContext(TrackingContext)

export default useTracking
