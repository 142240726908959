/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext } from 'react'

type FlattenedTrackProperties = {
  [key: string]: string
}

export const GlobalTrackingContext = createContext({
  trackFn: (
    _: string,
    __: FlattenedTrackProperties,
    ___: string,
    ____: string
  ) => {},
  trackPageFn: (_: string, __: FlattenedTrackProperties, ___?: string) => {},
  groupedEvents: {} as { [key: string]: string },
})

export const useGlobalTracking = () => useContext(GlobalTrackingContext)
