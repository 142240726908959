import React from 'react'
import { ThemeProvider } from '@emotion/react'
import {
  FundConfig,
  getFundConfig,
} from '../FundConfiguration/FundConfiguration'

const FundConfigContext = React.createContext<FundConfig>({
  ...getFundConfig(),
})

export const FundConfigProvider = ({
  children,
  isAdmin,
}: {
  children: React.ReactNode
  isAdmin?: boolean
}) => {
  const { theme, adminTheme, themeProvider } = getFundConfig()
  const FundThemeProvider = themeProvider

  return (
    <FundConfigContext.Provider value={getFundConfig()}>
      <ThemeProvider theme={isAdmin ? adminTheme : theme}>
        <FundThemeProvider isAdmin={isAdmin}>{children}</FundThemeProvider>
      </ThemeProvider>
    </FundConfigContext.Provider>
  )
}

FundConfigProvider.defaultProps = {
  isAdmin: false,
}

export const useFundConfig = () => React.useContext(FundConfigContext)
