/* eslint-disable import/prefer-default-export */

import { SvgIconProps, Theme } from '@mui/material'
import scholasticTheme, {
  adminScholasticTheme,
} from '../../theme/scholasticTheme'
import { Om721Logo } from '../logos/Om721Logo'
import ScholasticThemeProvider from '../ScholasticThemeProvider/ScholasticThemeProvider'

export type FundConfig = {
  companyName: string
  fundName: string
  shortName: string
  addressPlaceholder: string
  faviconPath: string
  logo: React.FC<SvgIconProps>
  logoWidthToHeight: number
  supportEmail: string
  supportPhone: string
  prettyPhone: string
  theme: Theme
  adminTheme: Theme
  themeProvider: React.FC<{ children: React.ReactNode; isAdmin?: boolean }>

  defaultDistributionFactor: number
  defaultOnboardingFee: number
  defaultClosingAndLegalFee: number
  defaultPricePerShare: number
  sharePriceLastUpdated: string
  managementFee: number
  defaultFundEquityPercentTarget: number
  fundStartingYear: number
  fundStartingQuarter: number
  validStates: string[]

  defaultSalesOperator: string
  calendlyScheduleURL: string
  calendlyLandingURL: string
}

const scholasticFundConfiguration: FundConfig = {
  companyName: 'Scholastic Capital',
  fundName: 'Scholastic Capital 721 Fund',
  shortName: 'scholastic',
  faviconPath: 'src/images/favicon.png',
  addressPlaceholder: '3442 S Ulster St Denver, CO 80231',
  logo: Om721Logo,
  logoWidthToHeight: 7.44,
  supportEmail: 'william@flockhomes.com',
  supportPhone: '+14079745463',
  prettyPhone: '(407) 974-5463',
  theme: scholasticTheme,
  adminTheme: adminScholasticTheme,
  themeProvider: ScholasticThemeProvider,

  defaultDistributionFactor: 0.04,
  defaultOnboardingFee: 0.035,
  defaultClosingAndLegalFee: 0.01,
  defaultPricePerShare: 1000,
  sharePriceLastUpdated: 'Jun 30, 2023',
  managementFee: 0.01,
  defaultFundEquityPercentTarget: 1,
  fundStartingYear: 2021,
  fundStartingQuarter: 2,
  validStates: ['FL', 'TN'],

  defaultSalesOperator: 'Jacob Schnapp',
  calendlyScheduleURL:
    'https://calendly.com/flockhomes/scholastic-capital-721-fund',
  calendlyLandingURL: 'https://calendly.com/flockhomes',
}

// When we have more funds, this will switch between them
export const getFundConfig = () => scholasticFundConfiguration
